"use client"

import { useRef } from "react"
import { StoryStoreProps, useStoryStore } from "./story-store"

export const StoryStoreInitializer = ({ story }: StoryStoreProps) => {
  const initialized = useRef(false)

  if (!initialized.current) {
    useStoryStore.setState({ story: story })
    initialized.current = true
  }

  return null
}
